<template>
  <div class="d-flex flex-column px-3 mt-2">
    <div class="d-flex flex-row align-items-center mb-3">
      <router-link :to="{ name: 'manager' }" class="btn btn-light mr-2">
        <i class="ri-arrow-left-line mr-50" />
        <span class="mr-50">Retour</span>
      </router-link>
      <div class="v-divider" />
      <p class="mb-0 mx-2 text-lg text-black-500">
        <strong>Création d'un modèle</strong>
      </p>
    </div>

    <h3 class="mb-2">Nouveau modèle</h3>
    <div class="row mb-3">
      <div class="col-xxl-2 col-xl-3 col-md-6 col-12">
        <card-file
          :title="'Modèle vierge'"
          :type="'new'"
          class="cursor-pointer p-0"
          @click.native="newModelFrom(null)"
        />
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6 col-12">
        <card-file
          :title="'Certificat d\'authenticité'"
          :type="'new'"
          :template="'auth-certificate'"
          class="cursor-pointer p-0"
          @click.native="newModelFromTemplate('auth-certificate')"
        />
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6 col-12">
        <card-file
          :title="'Certificat d\'authenticité Le Collectif'"
          :type="'new'"
          :template="'collectif-auth-certificate'"
          class="cursor-pointer p-0"
          @click.native="newModelFromTemplate('collectif-auth-certificate')"
        />
      </div>
    </div>

    <div
      class="mb-2 d-flex flex-row justify-content-between align-items-center"
    >
      <h3>Modèles existants</h3>
      <b-input-group style="max-width: 300px">
        <b-input-group-prepend>
          <span class="input-group-text"><i class="ri-search-line" /></span>
        </b-input-group-prepend>
        <b-form-input
          v-model="searchInput"
          class="search-input"
          placeholder="Rechercher un modèle"
          debounce="500"
          type="search"
        />
      </b-input-group>
    </div>

    <div v-if="modelsLoading" class="text-center">
      <b-spinner class="mt-4" variant="primary" />
    </div>
    <div v-else class="row">
      <div
        v-for="model in rows"
        :key="`recent-models-${model.id}`"
        @click="newModelFrom(model.id)"
        class="col-xxl-2 col-xl-3 col-md-6 col-12 mb-2"
      >
        <card-file
          :title="model.title"
          :type="model.type"
          :created-at="model.createdAt"
          :updated-at="model.updatedAt"
          class="cursor-pointer p-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardFile from "@/components/CardFile";
import axios from "@axios";

//templates json content
import AuthCertificateTemplate from "@/helpers/templates/auth-certificate";
import CollectifAuthCertificateTemplate from "@/helpers/templates/collectif-auth-certificate";

export default {
  components: {
    CardFile,
  },
  data() {
    return {
      rows: [],
      totalRecords: 0,
      searchInput: "",
      modelsLoading: true,
    };
  },
  watch: {
    searchInput(val, _) {
      this.fetchItemsFromApi(val);
    },
  },
  mounted() {
    this.fetchItemsFromApi(null);
  },
  methods: {
    newModelFrom(id) {
      if (id) {
        axios
          .get(`/document-templates/${id}`)
          .then((res) => {
            axios
              .post("/document-templates", {
                title: `Copie de ${res.data.title}`,
                content: res.data.content,
                type: res.data.type,
                dynamicFields: res.data.dynamicFields,
              })
              .then((res) => {
                this.$router.push({
                  name: "editor",
                  params: { id: res.data.id },
                });
              });
          })
          .catch((err) => {
            alert(err.toString());
          });
      } else {
        axios
          .post("/document-templates", {
            title: "Document sans titre",
            content: "",
            type: "FILLED",
          })
          .then((res) => {
            this.$router.push({ name: "editor", params: { id: res.data.id } });
          })
          .catch((err) => {
            alert(err.toString());
          });
      }
    },
    /**
     * Creates a new document from a template
     */
    newModelFromTemplate(templateName) {
      if (templateName === 'auth-certificate') {
        axios
          .post("/document-templates", {
            template: templateName,
            title: "Certificat d'authenticité sans titre",
            content: AuthCertificateTemplate,
            type: "FILLED",
          })
          .then((res) => {
            this.$router.push({ name: "editor", params: { id: res.data.id } });
          })
          .catch((err) => {
            alert(err.toString());
          });
      }
      else if (templateName === 'collectif-auth-certificate') {
        axios
          .post("/document-templates", {
            template: templateName,
            title: "Certificat d'authenticité Le Collectif sans titre",
            content: CollectifAuthCertificateTemplate,
            type: "FILLED",
          })
          .then((res) => {
            this.$router.push({ name: "editor", params: { id: res.data.id } });
          })
          .catch((err) => {
            alert(err.toString());
          });
      }
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi(title_contains) {
      this.modelsLoading = true;

      const getApiParams = {
        title_contains,
        limit: 12,
        page: 1,
        sortBy: `updatedAt:desc`,
      };

      axios
        .get("/document-templates", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.modelsLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) this.sessionExpired();
          this.modelsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
h3 {
  font-weight: 400;
}
</style>